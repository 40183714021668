import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/Layout';
import { AboutSidebarLinks } from '../../helpers/constants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="FAQ" sidebarLinks={AboutSidebarLinks} activeHeaderLink="About" mdxType="Layout">
      <h1>{`FAQ`}</h1>
      <h3>{`Is EEW part of the EPA?`}</h3>
      <p>{`No, EEW is a project of EDGI and is independent from the EPA and the US Government. We do however work with public datasets many of which come from the EPA.`}</p>
      <h3>{`Is this data biased?`}</h3>
      <p>{`All data contains bias - we hope that by making the data more accessible it can be held to higher and more objective standards by the community.`}</p>
      <h3>{`Any other projects we should know about?`}</h3>
      <p>{`Yes! Check out `}<a parentName="p" {...{
          "href": "https://www.apeoplesepa.org"
        }}>{`A People's EPA (APE)`}</a>{`. APE is a website for everyone—the public, students, activists, journalists—to understand the Environmental Protection Agency's national and global importance. Since its creation in 1970, Congress has charged the agency with implementing laws that protect us from air pollution, dirty water, and hazardous waste, among other things. This process is complex. Our website provides a straightforward history of the agency, explains how it operates, and provides the data and tools for you to explore and track the EPA in greater depth. This is an ongoing project of the Environmental Data & Governance Initiative. Check back for more updates coming soon!`}</p>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      